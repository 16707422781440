import { useState } from "react";
import { FcFolder, FcOpenedFolder, FcFile } from "react-icons/fc";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdClear,
} from "react-icons/md";

const FileExplorer = ({ folderData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(folderData);

  // Función para expandir y colapsar carpetas al hacer clic
  const toggleFolder = (folder) => {
    folder.showChildren = !folder.showChildren;
    setFilteredData({ ...filteredData });
  };

  // Función recursiva para manejar búsqueda
  const filterData = (data, searchValue) => {
    const searchQuery = searchValue.toLowerCase().trim();
    const matches = data.name.toLowerCase().includes(searchQuery);
    const children = data.children
      ?.map((child) => filterData(child, searchValue))
      .filter((child) => child.matches || child.children?.length > 0);

    return {
      ...data,
      showChildren: matches || children?.length > 0, // Expandir si hay coincidencias
      matches,
      children: children || [],
    };
  };

  // Función para expandir y filtrar solo las carpetas relevantes
  // const expandTreeForSearch = (data, searchValue) => {
  //   // const matches = data.name.toLowerCase() === searchValue.toLowerCase();
  //   const matches = data.name.toLowerCase().includes(searchValue.toLowerCase());
  //   const children =
  //     data.children?.map((child) => expandTreeForSearch(child, searchValue)) || [];

  //     const hasMatchingChild = children.some(
  //       (child) => child.matches || child.showChildren
  //     );

  //   // Mantener solo los hijos relevantes
  //   const visibleChildren = children.filter(
  //     (child) => child.showChildren || child.matches
  //   );

  //   // Este nodo debe expandirse si coincide o tiene hijos relevantes
  //   // const shouldExpand = matches || visibleChildren.length > 0;
  //   const shouldExpand = matches  || hasMatchingChild;

  //   const showChildren = matches && data.type === "file" ? true : hasMatchingChild;

  //   return {
  //     ...data,
  //     children: children.filter(
  //       (child) => child.matches || child.showChildren || searchValue === ""
  //     ),
  //     showChildren,
  //     matches,
  //   }
  // };

  // Manejo del input de búsqueda
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      // Restaurar el árbol completo si no hay búsqueda
      setFilteredData(folderData);
    } else {
      // Expandir el árbol según el término de búsqueda
      const result = filterData(folderData, value);
      setFilteredData({ ...result, showChildren: true });
    }
  };

  // Botón para limpiar el input de búsqueda
  const clearSearch = () => {
    setSearchTerm("");
    setFilteredData(folderData);
  };

  // Renderizar el árbol
  const renderTree = (data) => {
    if (!data) return null;

    const isFolder = data.type === "folder";

    return (
      <div>
        <h2 style={{ cursor: "pointer" }}>
          <span
            className="cursor-pointer"
            onClick={() => isFolder && toggleFolder(data)}
          >
            {isFolder ? (
              data.showChildren ? (
                <span className="pe-2 fs-1">
                  <FcOpenedFolder />
                </span>
              ) : (
                <span className="pe-2 fs-1">
                  <FcFolder />
                </span>
              )
            ) : (
              <span className="pe-2 fs-1">
                <FcFile />
              </span>
            )}

            {isFolder ? data.name : <a href={data.url} target="_blank" rel="noopener noreferrer">{data.name}</a>}
            {isFolder && (
              data.showChildren ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />
            )}
          </span>
        </h2>

        {data.showChildren &&
          data.children?.length > 0 &&
          data.children.map((child, index) => (
            <div key={index} className="ps-4">
              {renderTree(child)}
              {/* <FileExplorer key={index} folderData={child} isChild={true} /> */}
            </div>
          ))}
      </div>
    );
  };

  const hasResults = filteredData.matches || filteredData.children?.length > 0;

  return (
    <>
        <div className="d-flex mb-4">
          <input
            type="text"
            className="form-control me-2"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <button
            className="btn btn-danger d-flex align-items-center"
            onClick={clearSearch}
            title="Limpiar Búsqueda"
          >
            <MdClear size={20} />
          </button>
        </div>
      

      {hasResults ? (
        renderTree(filteredData)
      ) : (
        <p>No se encontraron resultados</p>
      )}
    </>
  );
};

export default FileExplorer;
