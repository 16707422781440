import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

//vistas
import AboutUs from "../views/about-us/about-us";
import ExecutiveBoard from "../views/executive-board/executiveBoard";
import HomeMain from "../views/home/home-main";
import Athletes from "../views/athletes/athletes";
import Events from "../views/events/events";
import Coaches from "../views/coaches/coaches";
import Fields from "../views/fields/fields";
import Privacy from "../views/privacy/Privacy";
import TermsAndConditions from "../views/terms-and-conditions/terms-and-conditions";
import FieldDetails from "../views/fields/FieldDetails";
import ErrorNoDisponible404 from "../Components/error404/error404";

//Componentes
import ScrollToTop from "../Components/scrollToTop/scrollToTop";
import ContactUs from "../views/contact-us/contact-us-main/contact-us-main";
import Records from "../views/records/records";
import ThankYou from "../views/contact-us/contact-form/thankyou";
import ProtectedRoute from "../Components/protected-route/protected-route";
import PostDetail from "../Components/post-detail/post-detail";
import Documents from "../views/documents/documents";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeMain />,
    errorElement: <ErrorNoDisponible404 />
  },
  {
    path: "/home",
    element: <HomeMain />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/executive-board",
    element: <ExecutiveBoard />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/athletes",
    element: <Athletes />,
  },
  {
    path: "/coaches",
    element: <Coaches />,
  },
  {
    path: "/fields",
    element: <Fields />,
  },
  {
    path: "/fields/:fieldName",
    element: <FieldDetails />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/terms-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/records",
    element: <Records />
  },
  {
    path: "/thankyou",
    element: <ProtectedRoute />
  },
  {
    path: "/post/:slug",
    element: <PostDetail />
  },
  {
    path: "/documents",
    element: <Documents />
  },
]);

const MyRoutes = () => (
  <RouterProvider router={router}>
    <ScrollToTop />
  </RouterProvider>
);

export default MyRoutes;
