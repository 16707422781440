import React, { useState, useEffect } from "react";
import './documents.css'
import ScrollToTop from '../../Components/scrollToTop/scrollToTop'
import NavBar from '../../Components/navbar/navbar'
import Footer from '../../Components/footer/footer'
import FileExplorer from '../../Components/file-explorer/fileExplorer'

const Documents = () => {

  const [folderData, setFolderData] = useState(null);

  useEffect(() => {
    fetch("https://worldarcherymexico.com/administrador/estructura.json")
      .then((response) => response.json())
      .then((data) => setFolderData(data))
      .catch((error) => console.error("Error cargando el JSON:", error));
  }, []);


  return (
    <>
      <ScrollToTop />
      <NavBar />
      <div className="file-explorer-hero">
        <div className='container'>
          {folderData ? (
            <FileExplorer folderData={folderData} />
          ) : (
            <p>Cargando archivos...</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Documents